/*
 * @Autor: lh
 * @Date: 2022-07-27 16:00:38
 * @LastEditors: lh
 * @LastEditTime: 2022-07-27 16:34:34
 * @Description: 
 */
import Element from 'element-plus'
// import 'default-passive-events'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/icon/iconfont.css'    // 引入阿里云字体图标css
import 'default-passive-events'
import { createApp } from 'vue'
import {createPinia} from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
const pinia = createPinia()
pinia.use(piniaPersist)
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 防止el-button重复点击
app.directive('onceClick',{
    mounted(el, binding, vnode) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true;
                setTimeout(() => {
                    el.disabled = false;
                }, binding.value || 1000);
            }
        });
    }
})
app.use(Element).use(router).use(pinia).mount('#app')
