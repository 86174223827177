import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'login',
    component: ()=>import('@/views/Login')
  },
  {
    path: '/NumScorll',
    name: 'NumScorll',
    component: ()=>import('@/components/NumScorll')
  },
  {
    path: '/discuss.vue',
    name: 'Index',
    component: ()=>import('@/components/index'),
    redirect:'/home',
    children:[
      {
    path: '/home',
    name: 'Home',
    meta:{
        title:'首页',
        isShow:true,
        Privilege:true,
        icon:'icon-shouye-copy'
        },
      component: ()=>import('@/views/home/index'),
      },
      {
        path: '/ification',
        name: 'Ification',
        meta:{
          title:'分类列表',
          isShow:true,
          icon:'icon-fenlei'
        },
        children:[
          {
            path: '/ificationSon',
            name: 'IficationSon',
            meta: {
              title: '商品分类',
            },
            component: () => import('@/views/ification/index'),
          }
        ]
      },
      {
        path: '/promote',
        name: 'Promote',
        meta:{
          title:'首页',
          isShow:true,
          icon:'icon-a-1811yingxiaotuiguangxiangqing-icon-ganxingqu'
        },
        children: [
          {
            path: '/promote1',
            name: 'Promote1',
            meta:{
              title:'首页推广类别列表',
            },
            component: ()=>import('@/views/promote/index'),
          },
          {
            path: '/navigation',
            name: 'navigation',
            meta:{
              title:'导航栏列表',
            },
            component: ()=>import('@/views/promote/navigation'),
          },
        ]
      },
      {
        path: '/banner',
        name: 'Banner',
        meta:{
          title:'banner',
          isShow:true,
          icon:'icon-guanggaolianmeng'
        },
        children:[{
          path: '/banner',
          name: 'Banner1',
          meta:{
            title:'banner',
          },
          component: ()=>import('@/views/Banner/index'),
        }]
      }, {
        path: '/order',
        name: 'Order',
        meta:{
          title:'订单列表',
          isShow:true,
          Privilege:true,
          icon:'icon-dingdanjihe'
        },
        children: [{
          path: '/order',
          name: 'Order',
          meta:{
            title:'订单列表',
          },
          component: ()=>import('@/views/order/index'),
        }]
      },
      {
        path: '/hostList',
        name: 'HostList',
        meta:{
          title:'用户列表',
          isShow:true,
          Privilege:true,
          icon:'icon-zhuboguanli'
        },
        children:[{
          path: '/hostList',
          name: 'HostList',
          meta:{
            title:'主播列表',
          },
          component: ()=>import('@/views/hostList/index'),
        },
        {
              path: '/user1',
              name: 'User1',
              meta:{
                title:'小程序用户列表',
              },
              component: ()=>import('@/views/User/index.vue'),
        }
        ]
      },
      {
        path: '/goodsList',
        name: 'GoodsList',
        meta:{
          title:'商品列表',
          isShow:true,
          Privilege:true,
          icon:'icon-duomaishangpin'
        },
        children: [
            {
          path: '/commodities',
          name: 'Commodities',
          meta:{
            title:'商品系列',
          },
          component: ()=>import('@/views/goodsList/commodities'),
        },{
          path: '/column',
          name: 'Column',
          meta:{
            title:'商品专栏',
          },
          component: ()=>import('@/views/goodsList/column'),
        },{
          path: '/curriculum',
          name: 'Curriculum',
          meta:{
            title:'商品课程',
          },
          component: ()=>import('@/views/goodsList/curriculum'),
        },
          {
          path: '/goodsList',
          name: 'GoodsList',
          meta:{
            title:'商品列表',
          },
          component: ()=>import('@/views/goodsList/index'),
        },
          {
            path: '/discuss',
            name: 'Discuss',
            meta:{
              title:'商品评论',
            },
            component: ()=>import('@/views/goodsList/discuss'),
          }]
      },
      {
        path:'/ngari1',
        name: 'Ngari1',
        meta:{
          title:'阿里点播分类',
          isShow:true,
          icon:'icon-dianbohuifang'
        },
        children: [
          {
            path: '/ngari',
            name: 'ngari',
            meta:{
              title:'阿里点播分类',
            },
            component: ()=>import('@/views/Ngari'),
          }
        ]
      },
      {
        path: '/setup',
        name: 'setupbox',
        meta:{
          title:'设置',
          isShow:true,
          icon:'icon-shezhitianchong'
        },
        children: [{
          path: '/setup',
          name: 'setup',
          meta:{
            title:'设置',
          },
          component: ()=>import('@/views/setup/index'),
        },
        // {
        //   path: '/canvas',
        //   name: 'canvas',
        //   meta:{
        //     title:'贪吃蛇',
        //   },
        //   component: ()=>import('@/views/setup/canvas'),
        // }
      ]
      },

    ]
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to,form,next)=>{
  if(to.name != 'login'){
    if(localStorage.getItem('token')){
      next()
    }else{
      next('/')
    }
  }else{
    next()
  }
  // next()
})
export default router
