<template>
  <router-view/>
</template>
<style lang="scss">
*{
  padding: 0;
  margin: 0;
}
body,html,#app{
  width: 100%;
  height: 100%;
}
</style>
